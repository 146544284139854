import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY_UID } from '@app/core/constants';
import { BaseGroupItem, BaseItem, UUID } from '@app/core/models/common.model';
import { Company } from '@app/core/models/company.model';
import { Currency } from '@app/core/models/currency.model';
import { Industry } from '@app/core/models/industry.model';
import { MetricChartView } from '@app/core/models/metric-chart.model';
import { Peer, PeerData } from '@app/core/models/peer.model';
import { AllSegments, AllSegmentsItems, SegmentItem, } from '@app/core/models/segment.model';
import { cache } from '@app/core/operators';
import {
  ActivityTrackerService,
  TrackingCategory,
  TrackingSubCategory,
} from '@app/api/tracking/activity-tracker.service';
import { ApiService, BaseCreateResponse } from '@app/core/services/api.service';
import { BusinessFunctionService } from '@app/core/services/business-function.service';
import { CurrencyService } from '@app/core/services/currency.service';
import { NotificationsService } from '@app/core/services/notifications.service';
import { PeerService } from '@app/core/services/peer.service';
import { SegmentService } from '@app/core/services/segment.service';
import { SolutionsService } from '@app/core/services/solutions.service';
import { UserSettingsService } from '@app/core/services/user-settings.service';
import { UserService } from '@app/core/services/user.service';
import { FishboneRow, FishboneStepType, } from '@app/shared/fishbone/fishbone.model';
import { untilDestroyed } from '@ngneat/until-destroy';
import { Dictionary, flatten, isEqual, isNil, omitBy, uniqBy } from 'lodash';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  lastValueFrom,
  map,
  Observable,
  of,
  shareReplay,
  skip,
  Subscription,
  switchMap,
  take,
  tap,
} from 'rxjs';

import {
  AdditionalPursuitData,
  AdditionalPursuitPreset,
  MetricPursuitPreset,
  PursuitPresetType,
  PursuitSelectionSet,
} from '../company.model';
import { CompanyComponentAmplitudeService } from './company-component-amplitude.service';
import { CompanyComponentDataService } from './company-component-data.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class CompanyComponentService {
  static readonly blockCid = 'CompanyComponentService';
  static readonly reportLoadBlockCid = 'CompanyComponentService';
  static readonly peersCid = `${ CompanyComponentService.blockCid }_Peers`;
  static readonly filtersCid = `${ CompanyComponentService.blockCid }_Filters`;

  readonly defaultMarkedPursuit: PursuitSelectionSet = {
    metricPresets: [],
    additionalPresets: [],
  };
  private _companyPursuitSubscription: Subscription;

  private _businessFunctions$: Observable<BaseItem[]>;
  private _recommendedPeers$: Observable<Peer[]>;
  private _segments$: Observable<AllSegments>;

  private _currencyFilter = new BehaviorSubject<Currency>(null);
  private _functionFilter = new BehaviorSubject<string>(null);
  private _view = new BehaviorSubject<MetricChartView>(MetricChartView.Years);
  private _peerFilter = new BehaviorSubject<Peer[]>(null);
  private _selectedSolution = new BehaviorSubject<string[]>([]);
  private _selectedBusinessSegment = new BehaviorSubject<string>(null);
  private _selectedGeographicSegment = new BehaviorSubject<string>(null);

  private _markedForPursuit = new BehaviorSubject<PursuitSelectionSet>(
    this.defaultMarkedPursuit
  );

  private _scrollToAnchor = new BehaviorSubject<string>(null);

  private _moduleFields: Dictionary<any> = {};
  private _prevSavedPeers: string[] = null;

  constructor(
    private _companyComponentDataService: CompanyComponentDataService,
    private _apiService: ApiService,
    private _router: Router,
    private _businessFunctionService: BusinessFunctionService,
    private _notificationsService: NotificationsService,
    private _activityTrackerService: ActivityTrackerService,
    private _solutionsService: SolutionsService,
    private _userService: UserService,
    private _userSettingsService: UserSettingsService,
    private _peerService: PeerService,
    private _segmentService: SegmentService,
    private _companyComponentAmplitudeService: CompanyComponentAmplitudeService,
    private _currencyService: CurrencyService
  ) {
    this._markedForPursuit
      .pipe(filter((item) => item !== this.defaultMarkedPursuit))
      .subscribe(() => {
        if (this._companyPursuitSubscription == null) {
          this._companyPursuitSubscription = this._companyComponentDataService
            .getSelectedCompany()
            .pipe(
              distinctUntilChanged(
                (a: Company, b: Company) => a?.uid === b?.uid
              ),
              skip(1),
              take(2)
            )
            .subscribe(() => {
              this._companyPursuitSubscription = null;
              this.clearMarkedForPursuit();
            });
        }
      });
  }

  //#region Tab fields
  saveModuleField(key: string, value: any): void {
    this._moduleFields[key] = value;
  }

  getModuleFields(): Dictionary<any> {
    return this._moduleFields;
  }

  clearModuleFields(): void {
    this._moduleFields = {};
  }

  //#endregion

  //#region Filters data
  getBusinessFunctions(): Observable<BaseItem[]> {
    if (this._businessFunctions$ == null) {
      this._businessFunctions$ = this._companyComponentDataService
        .getCompanyIndustry()
        .pipe(
          distinctUntilChanged((a, b) => a?.uid === b?.uid),
          switchMap((companyIndustry: Industry) =>
            this._businessFunctionService.getIndustryBusinessFunctions(
              companyIndustry.uid,
              true
            )
          ),
          tap((items) => {
            const selectedFunction = this._functionFilter.getValue();

            if (
              selectedFunction != null &&
              items.find((item) => item.uid === selectedFunction) == null
            ) {
              this._functionFilter.next(null);
            }
          }),
          cache()
        );
    }

    return this._businessFunctions$;
  }

  getRecommendedPeers(): Observable<Peer[]> {
    if (this._recommendedPeers$ == null) {
      this._recommendedPeers$ = this._companyComponentDataService
        .getSelectedNotEmptyCompany()
        .pipe(
          distinctUntilChanged((a, b) => a?.uid === b?.uid),
          switchMap((selectedCompany) =>
            this._peerService
              .getCompanyPeers(
                selectedCompany.uid,
                true,
                null,
                CompanyComponentService.peersCid
              )
              .pipe(
                map((data: PeerData) => {
                  if (data.savedPeers?.length > 0) {
                    const saved = data.savedPeers.map((peer: Peer) => peer.uid);
                    const defaultRecommenced = data.recommendedPeers?.slice(
                      0,
                      4
                    );
                    if (
                      !defaultRecommenced.every((peer: Peer) =>
                        saved.includes(peer.uid)
                      ) ||
                      defaultRecommenced.length !== saved.length
                    ) {
                      this._peerFilter.next(data.savedPeers);
                    }
                    this._prevSavedPeers = saved;
                  }

                  return data.recommendedPeers;
                })
              )
          ),
          cache()
        );
    }

    return this._recommendedPeers$;
  }

  getCurrencyFilterRaw(): Observable<Currency> {
    return this._currencyFilter.asObservable();
  }

  getCurrencyFilter(): Observable<Currency> {
    return this.getCurrencyFilterRaw().pipe(
      switchMap((currency) =>
        currency ? of(currency) : this.getDefaultCurrency()
      )
    );
  }

  getFunctionFilterRaw(): Observable<string> {
    return this._functionFilter.asObservable();
  }

  getFunctionFilter(): Observable<string> {
    return this.getFunctionFilterRaw().pipe(
      map((businessFunction) =>
        businessFunction ? businessFunction : EMPTY_UID
      )
    );
  }

  getPeerFilterRaw(): Observable<Peer[]> {
    return this._peerFilter.asObservable();
  }

  getPeerFilter(): Observable<Peer[]> {
    return this.getPeerFilterRaw().pipe(
      switchMap((peers) =>
        peers != null
          ? of(peers)
          : this.getRecommendedPeers().pipe(
            map((data: Peer[]) => data?.slice(0, 4))
          )
      )
    );
  }

  getView(): Observable<MetricChartView> {
    return this._view.asObservable();
  }

  async setCurrencyFilter(currency: Currency) {
    const defaultCurrency = await firstValueFrom(this.getDefaultCurrency());
    this._currencyFilter.next(
      currency?.uid !== defaultCurrency?.uid ? currency : null
    );

    this._companyComponentAmplitudeService.triggerAmplitude(
      'Currency filter change',
      {
        value: currency.name,
      }
    );
  }

  async setFunctionFilter(businessFunction: string): Promise<void> {
    this._functionFilter.next(
      businessFunction !== EMPTY_UID ? businessFunction : null
    );
    const businessFunctions = await firstValueFrom(this.getBusinessFunctions());

    this._companyComponentAmplitudeService.triggerAmplitude(
      'Business Function filter change',
      {
        value: businessFunctions.find((item) => item.uid === businessFunction)
          ?.name,
      }
    );
  }

  async setPeerFilter(peers?: Peer[]): Promise<void> {
    let defaultPeers = await firstValueFrom(this.getRecommendedPeers());
    defaultPeers = defaultPeers?.slice(0, 4);

    const defaultPeersObject = defaultPeers.reduce((result, peer) => {
      result[peer.uid] = true;
      return result;
    }, {});

    const isDefaultSelected =
      defaultPeers.length === peers?.length &&
      peers?.every((peer) => !!defaultPeersObject[peer.uid]);
    this._peerFilter.next(!isDefaultSelected ? peers : null);

    const company = await firstValueFrom(
      this._companyComponentDataService.getSelectedNotEmptyCompany()
    );

    this._companyComponentAmplitudeService.triggerAmplitude(
      peers?.length > 0
        ? 'Peer filter change'
        : 'Peer filter - Reset to Default Click',
      {
        values: (peers?.length > 0 ? peers : defaultPeers).map(
          (value) => value.name
        ),
      }
    );

    const newSavedPeers =
      peers != null ? peers.map((peer: Peer) => peer.uid) : [];
    if (
      this._prevSavedPeers == null ||
      !newSavedPeers.every((item) => this._prevSavedPeers.includes(item)) ||
      newSavedPeers.length !== this._prevSavedPeers?.length
    ) {
      this._peerService.savePeersForCompany(company.uid, newSavedPeers);
      this._prevSavedPeers = newSavedPeers;
    }
  }

  setView(view: MetricChartView): void {
    this._view.next(view);

    this._companyComponentAmplitudeService.triggerAmplitude(
      'Data View filter change',
      {
        value:
          this._view.getValue() === MetricChartView.Years
            ? 'Annual Data'
            : 'Latest 12 Months',
      }
    );
  }

  getSegments(): Observable<AllSegments> {
    if (this._segments$ == null) {
      this._segments$ = combineLatest([
        this._companyComponentDataService.getSelectedNotEmptyCompany(),
        this.getCurrencyFilter(),
      ]).pipe(
        distinctUntilChanged(isEqual),
        switchMap(([ company, currency ]: [ Company, Currency ]) =>
          this._segmentService.getAllCompaniesSegments(
            company.uid,
            currency.uid,
            CompanyComponentService.filtersCid
          )
        ),
        untilDestroyed(this, 'clearData'),
        shareReplay(1)
      );
    }

    return this._segments$;
  }

  clearData(): void {
    this._businessFunctions$ = null;
    this._recommendedPeers$ = null;
    this._segments$ = null;
  }

  getSegmentsItems(): Observable<AllSegmentsItems> {
    return this.getSegments().pipe(
      map((data) => this.addEmptySegmentsItems(data)),
      cache()
    );
  }

  addEmptySegmentsItems(data: AllSegments): AllSegmentsItems {
    return this._segmentService.addEmptySegments({
      business: data != null ? data.businessSegments : [],
      geographic: data != null ? data.geographicSegments : [],
    });
  }

  getEmptySegments(name: string, items: Array<SegmentItem>) {
    return this._segmentService.createEmptySegments(name, items);
  }

  getSelectedBusinessSegment(): Observable<string> {
    return this._selectedBusinessSegment.pipe(
      map((data) => (data != null ? data : EMPTY_UID))
    );
  }

  getSelectedBusinessSegmentRaw(): Observable<string> {
    return this._selectedBusinessSegment.asObservable();
  }

  async setBusinessSegment(value: string): Promise<void> {
    this._selectedBusinessSegment.next(value !== EMPTY_UID ? value : null);

    const businessSegmentName = await firstValueFrom(
      this.getSegmentsItems().pipe(
        map(
          (item) =>
            item.business.find(
              (businessSegment) => businessSegment.uid === value
            ).name
        )
      )
    );

    this._companyComponentAmplitudeService.triggerAmplitude(
      'Business Segment filter change',
      {
        value: businessSegmentName,
      }
    );
  }

  getSelectedGeographicSegment(): Observable<string> {
    return this._selectedGeographicSegment.pipe(
      map((data) => (data != null ? data : EMPTY_UID))
    );
  }

  getSelectedGeographicSegmentRaw(): Observable<string> {
    return this._selectedGeographicSegment.asObservable();
  }

  async setGeographicSegment(value: string): Promise<void> {
    this._selectedGeographicSegment.next(value !== EMPTY_UID ? value : null);

    const geographicSegmentName = await firstValueFrom(
      this.getSegmentsItems().pipe(
        map(
          (item) =>
            item.geographic.find(
              (geographicSegment) => geographicSegment.uid === value
            ).name
        )
      )
    );

    this._companyComponentAmplitudeService.triggerAmplitude(
      'Geographic Segment filter change',
      {
        value: geographicSegmentName,
      }
    );
  }

  getSolutionOptions(businessFunctionObservable: Observable<UUID>): Observable<BaseGroupItem[]> {
    return combineLatest([
      this._companyComponentDataService.getCompanyIndustry(),
      this._companyComponentDataService.getCompanySubIndustry(),
      this._userService.getCurrentUser(),
      businessFunctionObservable,
    ]).pipe(
      debounceTime(50),
      distinctUntilChanged(
        (a, b) =>
          a[0].uid === b[0]?.uid &&
          a[1]?.uid === b[1]?.uid &&
          a[2].clientUid === b[2].clientUid &&
          a[3] === b[3]
      ),
      switchMap(([ industry, subIndustry, user, businessFunctionUid ]) => {
        return this._solutionsService
          .getClientSolutionsByIndustries(
            user.clientUid,
            [ industry.uid ],
            [ subIndustry?.uid ],
            businessFunctionUid,
            CompanyComponentService.filtersCid
          )
      }),
    );
  }

  getSolutionOptionsWithEmptyOption(businessFunctionObservable: Observable<UUID>): Observable<BaseGroupItem[]> {
    return combineLatest([
      this.getSolutionOptions(businessFunctionObservable),
      this._userSettingsService.getSolutionTerminology()
    ])
      .pipe(
        map(([ solutionList, solutionTerm ]) => {
          const allSolution = [
            {
              uid: EMPTY_UID,
              name: `No ${ solutionTerm }`,
            },
          ];

          return solutionList[0]?.uid === EMPTY_UID
            ? [
              {
                ...solutionList[0],
                items: allSolution.concat(solutionList[0].items),
              },
            ].concat(solutionList.slice(1))
            : [
              {
                uid: EMPTY_UID,
                name: '',
                items: allSolution,
              },
            ].concat(solutionList)
        })
      )
  }

  getSolutions(businessFunctionObservable: Observable<UUID>): Observable<BaseGroupItem[]> {
    return this.updateSelectedSolutions(
      this.getSolutionOptionsWithEmptyOption(businessFunctionObservable)
    )
  }

  getSolutionsOptionsAndUpdateSelections(businessFunctionObservable: Observable<UUID>): Observable<BaseGroupItem[]> {
    return this.updateSelectedSolutions(
      this.getSolutionOptions(businessFunctionObservable)
    )
  }

  updateSelectedSolutions(solutionList: Observable<BaseGroupItem[]>): Observable<BaseGroupItem[]> {
    return solutionList
      .pipe(
        tap((solutions) => {
          const selectedSolution = this._selectedSolution.getValue();
          const flatSolutionList = solutions
            .reduce(
              (result: BaseItem[], item: BaseGroupItem) =>
                result.concat(item.items),
              []
            )
            .map(solution => solution.uid);
          const filteredList = selectedSolution?.filter(solutionUid => flatSolutionList?.includes(solutionUid))

          this._selectedSolution.next(filteredList);
        })
      );
  }

  getSelectedSolutionRaw(): Observable<string> {
    return this._selectedSolution.asObservable()
      .pipe(
        map((data) => (data?.length > 0 ? data[0] : null)),
      );
  }

  getSelectedSolutionsRaw(): Observable<string[]> {
    return this._selectedSolution.asObservable();
  }

  getSelectedSolution(): Observable<string> {
    return this._selectedSolution
      .pipe(
        map((data) => (data?.length > 0 ? data[0] : EMPTY_UID)),
      );
  }

  getSelectedSolutions(): Observable<string[]> {
    return this._selectedSolution;
  }


  async setSolution(value: string, pageParameter: string): Promise<void> {
    this._selectedSolution.next(value !== EMPTY_UID ? [ value ] : []);

    if (value !== EMPTY_UID) {
      const company = await firstValueFrom(
        this._companyComponentDataService.getSelectedCompany()
      );

      this._activityTrackerService.registerClick({
        category: TrackingCategory.Solution,
        subCategory: TrackingSubCategory.Company,
        parameter1: value,
        parameter2: company.uid,
        parameter3: pageParameter,
      });
    }

    this._companyComponentAmplitudeService.triggerAmplitude(
      'Solution filter change',
      {
        value: value || 'All Solutions',
      }
    );
  }

  async setSolutions(value: string[], pageParameter: string): Promise<void> {
    const newSolutions = value.filter(it => !this._selectedSolution.value.includes(it));
    this._selectedSolution.next(value)

    if (newSolutions?.length > 0) {
      const company = await firstValueFrom(
        this._companyComponentDataService.getSelectedCompany()
      );

      newSolutions.forEach(it =>
        this._activityTrackerService.registerClick({
          category: TrackingCategory.Solution,
          subCategory: TrackingSubCategory.Company,
          parameter1: it,
          parameter2: company.uid,
          parameter3: pageParameter,
        })
      );
    }

    this._companyComponentAmplitudeService.triggerAmplitude(
      'Solution filter change',
      {
        value: value?.length > 0 ? value.toString() : 'All Solutions',
      }
    );
  }

  resetGlobalFilters(saveReset = false): void {
    this._currencyFilter.next(null);

    this._functionFilter.next(null);
    this._selectedSolution.next([]);
    this._selectedBusinessSegment.next(null);
    this._selectedGeographicSegment.next(null);

    if (saveReset) {
      this.setPeerFilter(null);
    } else {
      this._peerFilter.next(null);
    }

    this._view.next(MetricChartView.Years);
  }

  //#endregion

  //#region Pursuit and Value modeler
  getMarkedForPursuit(): Observable<PursuitSelectionSet> {
    return this._markedForPursuit.asObservable();
  }

  async markMetricForPursuit(preset: MetricPursuitPreset): Promise<void> {
    const oldMarked = this._markedForPursuit.getValue();

    this._markedForPursuit.next({
      ...oldMarked,
      metricPresets:
        oldMarked.metricPresets.find(
          (markedItem) => markedItem.metric.uid === preset.metric.uid
        ) == null
          ? oldMarked.metricPresets.concat(preset)
          : oldMarked.metricPresets.map((markedItem) => {
            if (markedItem.metric.uid !== preset.metric.uid) {
              return markedItem;
            }

            return preset;
          }),
    });
  }

  demarkMetricForPursuit(metricUid: string): void {
    const oldMarked = this._markedForPursuit.getValue();
    this._markedForPursuit.next({
      ...oldMarked,
      metricPresets: oldMarked.metricPresets.filter(
        (preset: MetricPursuitPreset) => preset.metric.uid !== metricUid
      ),
    });
  }

  async markAdditionalPreset(
    presetType: PursuitPresetType,
    data: AdditionalPursuitData
  ): Promise<void> {
    const company = await firstValueFrom(
      this._companyComponentDataService.getSelectedNotEmptyCompany()
    );
    const oldMarked = this._markedForPursuit.getValue();
    let newMarked: PursuitSelectionSet;
    if (
      oldMarked.additionalPresets.find(
        (preset: AdditionalPursuitPreset) => preset.type === presetType
      ) != null
    ) {
      newMarked = {
        ...oldMarked,
        additionalPresets: oldMarked.additionalPresets.map(
          (preset: AdditionalPursuitPreset) => {
            if (preset.type === presetType) {
              return {
                ...preset,
                dataList: preset.dataList.concat(data),
              };
            }

            return preset;
          }
        ),
      };
    } else {
      newMarked = {
        ...oldMarked,
        additionalPresets: oldMarked.additionalPresets.concat({
          type: presetType,
          dataList: [ data ],
        }),
      };
    }

    this._markedForPursuit.next(newMarked);
  }

  demarkAdditionalPreset(
    presetType: PursuitPresetType,
    dataUid?: string
  ): void {
    const oldMarked = this._markedForPursuit.getValue();
    let additionalPresets: AdditionalPursuitPreset[];

    if (dataUid != null) {
      additionalPresets = oldMarked.additionalPresets
        .map((preset: AdditionalPursuitPreset) => {
          if (preset.type === presetType) {
            return {
              ...preset,
              dataList: preset.dataList.filter(
                (data: AdditionalPursuitData) => data.uid !== dataUid
              ),
            };
          }

          return preset;
        })
        .filter(
          (preset: AdditionalPursuitPreset) => preset.dataList.length !== 0
        );
    } else {
      additionalPresets = oldMarked.additionalPresets.filter(
        (preset: AdditionalPursuitPreset) => preset.type !== presetType
      );
    }

    this._markedForPursuit.next({
      ...oldMarked,
      additionalPresets,
    });
  }

  clearMarkedForPursuit(): void {
    this._markedForPursuit.next(this.defaultMarkedPursuit);
  }

  getRowsByType(
    selected: MetricPursuitPreset[],
    type: FishboneStepType
  ): FishboneRow[] {
    return uniqBy(
      selected.reduce((result: FishboneRow[], preset: MetricPursuitPreset) => {
        const presetFunctionsStep = preset.fishboneSteps.find(
          (fishbone) => fishbone.step.type === type
        );
        return result.concat(presetFunctionsStep?.rows || []);
      }, []),
      'uid'
    );
  }

  getAdditionalPresetsByType(
    values: AdditionalPursuitPreset[],
    type: PursuitPresetType
  ): AdditionalPursuitData[] {
    return values.find((item) => item.type === type)?.dataList;
  }

  async createPursuit(
    selectionSet: PursuitSelectionSet,
    company: Company
  ): Promise<any> {
    const geographicAddons = this.getAdditionalPresetsByType(
      selectionSet.additionalPresets,
      PursuitPresetType.BusinessSegment
    );
    const businessAddons = this.getAdditionalPresetsByType(
      selectionSet.additionalPresets,
      PursuitPresetType.GeographicalSegment
    );

    const result: BaseCreateResponse = await firstValueFrom(
      this._apiService
        .post<BaseCreateResponse>(
          'fishbone/myPursuit/company-pursuit',
          {
            companyUid: company.uid,
            pursuit: {
              selectedFishBone: {
                selectedFMetricsUids: selectionSet.metricPresets.map(
                  (selection) => selection.metric.uid
                ),
                selectedBFunctionsUids: this.getRowsByType(
                  selectionSet.metricPresets,
                  FishboneStepType.BusinessFunction
                ).map((row) => row.uid),
                selectedKpisUids: this.getRowsByType(
                  selectionSet.metricPresets,
                  FishboneStepType.KPI
                ).map((row) => row.uid),
                selectedSolutionsUids: this.getRowsByType(
                  selectionSet.metricPresets,
                  FishboneStepType.Solution
                ).map((row) => row.uid),
                selectedSolutionAreaUids: this.getRowsByType(
                  selectionSet.metricPresets,
                  FishboneStepType.SolutionAreas
                ).map((row) => row.uid),
              },
              pursuitFiltersData: {
                currencyUid: company.currencyUid,
                businessSegmentUid: geographicAddons
                  ? geographicAddons[0].uid
                  : undefined,
                geographicalSegmentUid: businessAddons
                  ? businessAddons[0].uid
                  : undefined,
              },
            },
          },
          'ADPC'
        )
        .pipe(
          tap(() =>
            this._notificationsService.removeNotification('create-pursuit')
          ),
          catchError(() => of(null))
        )
    );

    if (result != null) {
      this.clearMarkedForPursuit();

      this._activityTrackerService.registerCreate({
        category: TrackingCategory.Pursuit,
        subCategory: TrackingSubCategory.Company,
        parameter1: result.entityUid,
        parameter2: company.uid,
      });

      await this._router.navigate([ 'pursuit', result.entityUid ]);
    } else {
      this._notificationsService.removeNotification(
        NotificationsService.generalError
      );
      this._notificationsService.addError({
        id: 'create-pursuit',
        message:
        // eslint-disable-next-line @typescript-eslint/quotes
          "Sorry, the pursuit can't be created for some reason. Please try again later.",
        autoRemovable: true,
      });
    }
  }

  //#endregion

  getScrollToAnchor(): Observable<string> {
    return this._scrollToAnchor.asObservable();
  }

  setScrollToAnchor(anchor: string): void {
    this._scrollToAnchor.next(anchor);
  }

  async downloadReport(reportLink: string): Promise<void> {
    await lastValueFrom(
      this._apiService
        .get(
          `${ environment.apiGateway }${ reportLink }`,
          {
            preFillUrl: false,
          },
          CompanyComponentService.reportLoadBlockCid
        )
        .pipe(
          tap((result: { staticLink: string }) => {
            this.downloadCompanyReport(result.staticLink, true);
          }),
          catchError(() => of(null))
        )
    );
  }

  downloadSolutionAttachment(solutionLink: string): void {
    this.downloadCompanyReport(`${ environment.apiGateway }${ solutionLink }`);
  }

  downloadCompanyReport(staticLink: string, newTab = false): void {
    this._apiService.downloadByLink(
      staticLink || 'not-found',
      'report',
      newTab
    );
  }

  private getDefaultCurrency(): Observable<Currency> {
    return combineLatest([
      this._companyComponentDataService.getSelectedNotEmptyCompany(),
      this._currencyService.getAllCurrencies(),
      this._currencyService.getDefaultCurrency(),
    ]).pipe(
      map(([ company, currencies, defaultCurrency ]) => {
        const currencyId = company.currencyUid;
        const companyCurrency = currencies.find(
          (currency) => currency.uid === currencyId
        );
        return companyCurrency || defaultCurrency;
      })
    );
  }
}
